import React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"

const Success = () => {
  return (
    <Layout>
      <SEO title="Success" />
      <div>
        <div className="container px-6 pt-24 pb-10 pb-64 mx-auto sm:px-8 md:px-12 lg:px-6 md:pl-0 md:pr-0">
          <h1 className="pt-8 pb-8 leading-none">Success!</h1>
          <p className="pb-8">
            Your message has been sent! - Dr Yalda will be in touch with you
            soon
          </p>
          <p>
            View more{" "}
            <Link to="/treatments" className="bluelink">
              treatments
            </Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Success
